@import '../../../theme/styles/mixins';

.container {
  margin: 8px;
  max-width: 464px;

  @include mobile {
    margin: 8px;

    .title {
      text-align: center;
    }
  }
}

.subtitle {
  margin-right: 50px;

  @include mobile {
    margin-right: 0px;

    text-align: center;
  }
}

.searchInput {
  margin-top: 16px;
}
