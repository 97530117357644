.container {
  position: relative;

  img {
    z-index: 0;
    object-fit: cover;
  }

  > div {
    z-index: 1;
  }
}
